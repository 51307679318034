<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix">
      <span>{{ $t('create') }} {{ $tc('entity.plan', 1) }}</span>
    </div>
    <el-alert type="info" title="Limites">
      0: NO TIENE -1: ILIMITADO >0: Limitado
    </el-alert>
    <br />
    <el-form
      ref="formPlan"
      v-loading="loading"
      :model="plan"
      :rules="rulesPlan"
      label-position="top"
    >
      <el-tabs v-model="tabs.active">
        <el-tab-pane :label="$tc('plan')" name="plan">
          <el-row :gutter="20">
            <el-col :sm="12">
              <el-form-item :label="$tc('mode', 1)" prop="planMode">
                <select-plan-mode
                  v-model="plan.planMode"
                  @change="getFeatures({ id: plan.serviceId })"
                ></select-plan-mode>
              </el-form-item>
              <el-form-item :label="$t('service')" prop="serviceId">
                <select-service
                  v-model="plan.serviceId"
                  @set-selected="getFeatures"
                ></select-service>
              </el-form-item>
              <el-form-item :label="$tc('name', 1)" prop="planName">
                <el-input v-model="plan.planName" :maxlength="64"></el-input>
              </el-form-item>
              <el-form-item :label="$tc('price')">
                <table>
                  <thead>
                    <tr>
                      <th>{{ $tc('monthly') }}</th>
                      <th>{{ $tc('quarterly') }}</th>
                      <th>{{ $tc('semiannual') }}</th>
                      <th>{{ $tc('yearly') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <el-input
                          v-model.number="plan.planPriceMonthly"
                          type="number"
                        ></el-input>
                      </td>
                      <td>
                        <el-input
                          v-model.number="plan.planPriceQuarterly"
                          type="number"
                        ></el-input>
                      </td>
                      <td>
                        <el-input
                          v-model.number="plan.planPriceSemiannual"
                          type="number"
                        ></el-input>
                      </td>
                      <td>
                        <el-input
                          v-model.number="plan.planPriceYearly"
                          type="number"
                        ></el-input>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </el-form-item>
              <el-form-item :label="$tc('priceNew')">
                <table>
                  <thead>
                    <tr>
                      <th>{{ $tc('monthly') }}</th>
                      <th>{{ $tc('quarterly') }}</th>
                      <th>{{ $tc('semiannual') }}</th>
                      <th>{{ $tc('yearly') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <el-input
                          v-model.number="plan.planPriceMonthlyNew"
                          type="number"
                        ></el-input>
                      </td>
                      <td>
                        <el-input
                          v-model.number="plan.planPriceQuarterlyNew"
                          type="number"
                        ></el-input>
                      </td>
                      <td>
                        <el-input
                          v-model.number="plan.planPriceSemiannualNew"
                          type="number"
                        ></el-input>
                      </td>
                      <td>
                        <el-input
                          v-model.number="plan.planPriceYearlyNew"
                          type="number"
                        ></el-input>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </el-form-item>
              <el-form-item :label="$tc('taxPercent', 1)">
                <el-input v-model.number="planTaxPercent" type="number">
                  <template slot="prepend">
                    {{ $tc('isIncluded', 1) }} &nbsp;<input
                      v-model="planTaxIsIncluded"
                      type="checkbox"
                    />
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item :label="$t('description')">
                <el-input
                  v-model="plan.planDescription"
                  type="textarea"
                  :maxlength="200"
                ></el-input>
              </el-form-item>
              <el-row :gutter="10">
                <el-col :span="8">
                  <el-form-item :label="$t('onSite')">
                    <el-switch v-model="plan.planOnSite"></el-switch>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item :label="$t('free')">
                    <el-switch v-model="plan.planFree"></el-switch>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item :label="$t('isDefault')">
                    <el-switch v-model="plan.planIsDefault"></el-switch>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-form-item :label="$t('order')" prop="planOrder">
                <el-input
                  v-model.number="plan.planOrder"
                  type="number"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :sm="12">
              <el-table
                v-loading="loading"
                :data="plan && plan.features ? plan.features.filter(i => i.featureEnabled) : []"
                style="width: 100%"
              >
                <el-table-column label="Característica" width="240">
                  <template slot-scope="scope">
                    {{ scope.row.featureName }}
                    <el-tooltip
                      v-if="scope.row.featureDescription"
                      :content="scope.row.featureDescription"
                      effect="dark"
                      placement="right"
                    >
                      <span>
                        <i class="fad fa-info-circle text-info"></i>
                      </span>
                    </el-tooltip>
                    <el-tooltip
                      v-if="scope.row.featureIsControlled"
                      content="Controlado por plan/paquete"
                      effect="dark"
                      placement="right"
                    >
                      <span>
                        <i class="fad fa-cubes text-info"></i>
                      </span>
                    </el-tooltip>
                  </template>
                </el-table-column>
                <el-table-column>
                  <template slot-scope="scope">
                    <span v-if="scope.row.featureType === 'info'">
                      Incluido
                    </span>
                    <el-input
                      v-if="scope.row.featureType.toLowerCase().includes('limit')
                        "
                      v-model.number="scope.row.featureValue"
                      style="width: 100px"
                    ></el-input>
                    <el-switch
                      v-if="scope.row.featureType.toLowerCase() === 'access'"
                      v-model="scope.row.featureValue"
                    ></el-switch>
                  </template>
                </el-table-column>
                <el-table-column label="Precio c/u">
                  <template slot-scope="scope">
                    <el-input
                      v-if="scope.row.featureType
                        .toLowerCase()
                        .includes('openlimit')
                        "
                      v-model.number="scope.row.featureUnitPriceCustom"
                      style="width: 100px"
                    ></el-input>
                  </template>
                </el-table-column>
              </el-table>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane :label="$tc('promotion', 2)" name="promotion">
          <el-row :gutter="20">
            <el-col :sm="24">
              <el-form-item :label="$tc('promotion', 2)">
                <el-collapse accordion="">
                  <el-collapse-item name="yearly">
                    <template slot="title">
                      {{ $tc('yearly') }}
                    </template>
                    <promotion :list="plan.promotionYearly"></promotion>
                  </el-collapse-item>
                  <el-collapse-item name="monthly">
                    <template slot="title">
                      {{ $tc('monthly') }}
                    </template>
                    <promotion :list="plan.promotionMonthly"></promotion>
                  </el-collapse-item>
                  <el-collapse-item name="quarterly">
                    <template slot="title">
                      {{ $tc('quarterly') }}
                    </template>
                    <promotion :list="plan.promotionQuarterly"></promotion>
                  </el-collapse-item>
                  <el-collapse-item name="semiannual">
                    <template slot="title">
                      {{ $tc('semiannual') }}
                    </template>
                    <promotion :list="plan.promotionSemiannual"></promotion>
                  </el-collapse-item>
                </el-collapse>
              </el-form-item>
            </el-col>
          </el-row>
        </el-tab-pane>
      </el-tabs>
      <el-form-item>
        <el-button
          size="mini"
          type="primary"
          :loading="loading"
          @click="create"
        >
          {{ $t('save') }}
        </el-button>
        <router-link
          class="el-button el-button--text el-button--small"
          to="/plans"
          tag="button"
        >
          {{ $t('cancel') }}
        </router-link>
      </el-form-item>
    </el-form>
  </el-card>
</template>
<script>
import { i18n } from '../../i18n'
import model from './model'
import rules from './rules'
export default {
  name: 'PlanCreate',
  components: {
    promotion: _ => import('./promotion'),
  },
  data() {
    return {
      plan: { ...model() },
      tabs: { active: 'plan' },
      loading: false,
      rulesPlan: rules(),
    }
  },
  computed: {
    planTaxIsIncluded: {
      get: _this => _this.plan.planTaxPercentIncluded !== '' &&
        _this.plan.planTaxPercentIncluded !== null,
      set(value) {
        const _this = this
        if (value) {
          _this.plan.planTaxPercentIncluded = _this.planTaxPercent || 0
          _this.plan.planTaxPercentIncrease = ''
        } else {
          _this.plan.planTaxPercentIncrease = _this.planTaxPercent || 0
          _this.plan.planTaxPercentIncluded = ''
        }
      },
    },
    planTaxPercent: {
      get: _this => (_this.planTaxIsIncluded
        ? _this.plan.planTaxPercentIncluded
        : _this.plan.planTaxPercentIncrease),
      set(value) {
        const _this = this
        if (_this.planTaxIsIncluded) {
          _this.plan.planTaxPercentIncluded = value
          _this.plan.planTaxPercentIncrease = ''
        } else {
          _this.plan.planTaxPercentIncrease = value
          _this.plan.planTaxPercentIncluded = ''
        }
      },
    },
  },
  methods: {
    create() {
      const _this = this
      _this.$refs.formPlan.validate(valid => {
        if (valid) {
          _this.loading = true
          _this.$http.post('plans', _this.plan).then(
            response => {
              _this.$message({
                type: 'success',
                message: i18n.t('processSuccess'),
              })
              _this.plan.reset()
              _this.loading = false
            },
            response => {
              _this.loading = false
            },
          )
        } else {
          _this.$message.error('Oops, hay datos requeridos que debes ingresar!')

          return false
        }
      })
    },
    getFeatures({ id }) {
      const _this = this
      _this.loading = true
      _this.plan.features = []
      if (id) {
        _this.$http.get(`features/findByService/${id}`).then(
          response => {
            _this.plan.features = response.body

            if (_this.plan.planMode === 'paquetePeriodicidad') {
              console.log('%c⧭', 'color: #1d5673', _this.plan)
              _this.plan.features = _this.plan.features.filter(
                i => i.featureIsControlled,
              )
            }
            _this.loading = false
          },
          response => {
            _this.loading = false
          },
        )
      } else {
        _this.loading = false
      }
    },
  },
}
</script>
